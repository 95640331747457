<template>
  <div class="content">
    <div class="content-top">
      <div class="chin-title">人艺资讯</div>
      <div class="eng-title">People's Art Informa</div>
    </div>
    <div class="content-bottom">
      <div class="list-box">
        <div class="list">
          <div class="list-content">
            <div class="list-left">人艺新闻</div>
          </div>
        </div>
        <div
          @click="listClick(item.id, item.url)"
          v-for="item in list"
          :key="item.id"
          :class="id == item.id ? 'list-active' : 'list'"
        >
          <div class="list-content">
            <div v-if="item.isChild" class="before"></div>
            <div :class="item.isChild ? 'splist-left' : 'list-left'">
              {{ item.name }}
            </div>
            <div v-if="listId == item.id" class="list-right">——</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      list: [
        {
          id: 1,
          name: "剧院动态",
          url: "/ArtsInformation/dynamic",
          isChild: true,
        },
        {
          id: 2,
          name: "剧院公告",
          url: "/ArtsInformation/announcement",
          isChild: true,
        },
        {
          id: 3,
          name: "剧目推介",
          url: "/ArtsInformation/recommend",
          isChild: true,
        },
        {
          id: 4,
          name: "人艺活动",
          url: "/ArtsInformation/activity",
          isChild: false,
        },
        // {
        //   id: 5,
        //   name: "星光大合影",
        //   url: "/ArtsInformation/groupPhoto",
        //   isChild: false,
        // },
      ],
      listId: this.id,
    };
  },
  methods: {
    listClick(id, url) {
      if (this.listId !== id) {
        this.listId = id;
        this.$router.push(url);
      }
    },
  },
};
</script>
<style scoped lang="less">
.content {
  width: 15.625vw;
  height: 35.7292vw;
  position: relative;

  .content-top {
    width: 14.5833vw;
    height: 35.6771vw;
    // background-image: url('../../../assets/imgs/bg-list.png');
    background: url("../../../assets/imgs/bg-list.png") no-repeat;
    background-size: 100%;

    .chin-title {
      position: absolute;
      top: 1.5625vw;
      left: 1.0417vw;
      width: 6.6667vw;
      height: 1.6667vw;
      font-size: 1.6667vw;
      font-family: SourceHanSerifSC-Bold, SourceHanSerifSC;
      font-weight: bold;
      color: #ffffff;
      line-height: 1.6667vw;
    }

    .eng-title {
      position: absolute;
      top: 3.75vw;
      left: 1.0417vw;
      width: 8.2813vw;
      height: 0.7292vw;
      font-size: 0.7292vw;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #ffffff;
      line-height: 0.7292vw;
    }
  }

  .content-bottom {
    position: absolute;
    width: 14.5833vw;
    height: 30.2083vw;
    left: 1.0417vw;
    top: 5.5208vw;
    background: #ffffff;

    .list-box {
      width: 100%;
      height: 27.0833vw;
      margin-top: 1.5625vw;
      margin-bottom: 1.5625vw;

      .list {
        width: 100%;
        height: 2.6042vw;

        .list-content {
          margin-left: 1.5625vw;
          margin-right: 1.5625vw;
          width: 11.4583vw;
          height: 2.6042vw;
          display: flex;
          // justify-content: space-between;
          cursor: pointer;
          line-height: 2.6042vw;
          font-size: 0.8333vw;
          .before {
            width: 0.2083vw;
            height: 0.2083vw;
            background: #f9680d;
            margin-top: 1.1458vw;
            margin-left: 1.0417vw;
          }
          .list-left {
            margin-right: 6.4583vw;
            word-break: keep-all;
          }
          .splist-left {
            margin-left: 0.9375vw;
            margin-right: 3.2292vw;
          }
        }
      }
      .list-active {
        width: 100%;
        height: 2.6042vw;
        background: #21424b;
        color: #ffffff;
        font-size: 0.8333vw;

        .list-content {
          width: 11.4583vw;
          height: 2.6042vw;
          display: flex;
          cursor: pointer;
          line-height: 2.6042vw;
          margin-left: 1.5625vw;
          margin-right: 1.5625vw;
          .before {
            width: 0.2083vw;
            height: 0.2083vw;
            background: #f9680d;
            margin-top: 1.1458vw;
            margin-left: 1.0417vw;
          }
          .list-left {
            margin-right: 6.4583vw;
            word-break: keep-all;
          }
          .splist-left {
            margin-left: 0.9375vw;
            margin-right: 3.2292vw;
          }
        }
      }
    }
  }
}
</style>
